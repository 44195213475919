// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h, render } from "preact";
import merge from "lodash-es/merge";
import {
    WidgetConfig,
    BootstrapWidgetApi,
    EmbeddableComponent,
    InitializeIdentity,
    RecommendParams
} from "common/lib/interfaces";
import { Analytics } from "common/lib/utils";
import { uniqBy } from "lodash-es";
import App from "./app/App";
import {
    CONTEXTUAL_RECOMMENDS_WIDGET_DATA_ATTRIBUTE,
    EMBEDDABLE_PRODUCTS_WIDGET_DATA_ATTRIBUTE,
    SHOPPING_OUTLET
} from "./constants/components.registry";
import { IOpenWidgetParam } from "./app/config";
import "./version";
import { Theme } from "./constants/themes";
import {
    initUSCAnalytics,
    rescanUSCAnalytics,
    addErrorHandlers
} from "./utils/analytics";

import {
    loadCSS,
    loadPromisePolyfill,
    loadDefaultConfig,
    loadContextualRecommendationsWidget,
    loadAmazonPayScript,
    loadEmbeddableProductsWidget
} from "./utils/loader";
import { isPageWhitelisted } from "./utils/dom";
import { setZIndex, validateConfig, validateZIndex } from "./utils/misc";
import { getRecommendations } from "./utils/personify";
import { checkInitializeIdentity, initializeIdentity } from "./utils/identity";

declare const BASE_API_URL: string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let appElement: any;

window.uscWidget = {
    async init(cfg: WidgetConfig) {
        try {
            loadPromisePolyfill();

            window.mParticle?.ready(async () => {
                await initUSCAnalytics(cfg.networkBrand);
            });

            addErrorHandlers();

            const defaultConfig = await loadDefaultConfig(
                cfg.networkBrand,
                cfg?.theme
            );

            const appHTML = document.createElement("div");
            appHTML.setAttribute("id", SHOPPING_OUTLET);
            appHTML.classList.add(`usc-outlet--theme`);

            document.body.appendChild(appHTML);

            this.config = validateConfig(merge(defaultConfig, cfg));
            const zIndex = validateZIndex(this.config.zIndex);

            setZIndex(zIndex);

            if (
                this.config?.identity &&
                checkInitializeIdentity(this.config?.identity)
            ) {
                await initializeIdentity(
                    this.config.identity as Required<InitializeIdentity>
                );
            }

            if (this.config.amazonPay && this.config.amazonPay.enabled) {
                loadAmazonPayScript();
            }

            render(
                <App
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.config}
                    ref={el => {
                        appElement = el;
                    }}
                />,
                appHTML
            );
        } catch (error) {
            Analytics.logError(error);
        }
    },
    rescan() {
        try {
            if (appElement) {
                appElement.rescan();
            }

            rescanUSCAnalytics(this.config?.networkBrand);
        } catch (error) {
            Analytics.logError(error);
        }
    },
    async setTheme(theme: Theme, networkBrand?: string) {
        this.config = {
            ...this.config,
            theme,
            networkBrand: networkBrand || this.config.networkBrand
        };

        await loadCSS(
            `${BASE_API_URL}/v3/${this.config.networkBrand}/theme/${theme}`,
            "usc-theme-styles"
        )
            .then(() => console.log(`USC: ${theme} theme loaded`))
            .catch(err => console.log(`USC: ${theme} theme not loaded`, err));
    },
    open(productParams: IOpenWidgetParam) {
        if (appElement) {
            appElement.openWidget(productParams);
        }
    },

    async showRecommendations(recommendParams?: RecommendParams) {
        if (!this.config?.ccwEnabled || !this.config?.personifyApiUrl) {
            return null;
        }

        let productsCount = 0;
        const limit = recommendParams?.limit ?? 12;
        const recommendationProducts = await getRecommendations(
            { ...(recommendParams ?? {}), limit },
            this.config.personifyApiUrl
        );
        productsCount = Math.min(recommendationProducts.length, limit);

        if (productsCount) {
            const areSponsoredCardsAllowed =
                !this.config.enablePageTags ||
                isPageWhitelisted(this.config.enablePageTags);

            loadContextualRecommendationsWidget(
                this.config,
                uniqBy(recommendationProducts, "productcode"),
                productsCount,
                areSponsoredCardsAllowed
            );
        }

        return {
            productsCount
        };
    },

    async showEmbeddableProductsWidget(recommendParams?: RecommendParams) {
        const rootElements = document.querySelectorAll<HTMLElement>(
            `[${EMBEDDABLE_PRODUCTS_WIDGET_DATA_ATTRIBUTE}]`
        );

        if (!rootElements.length || !this.config.networkBrand) {
            console.error(
                "Skipping embeddable widget loading due to conditions:",
                "rootElements:",
                !rootElements.length,
                "networkBrand:",
                !this.config.networkBrand
            );
            return;
        }

        try {
            await loadEmbeddableProductsWidget(this.config);
        } catch (err) {
            console.error("Can't load USC Embeddable Widget", err);
        }

        const ccwEnabled = this.config.epw?.embeddedCcwEnabled;
        const ccwPosition = (this.config.epw?.embeddedCcwPosition || 1) - 1;
        const shouldRender = ccwEnabled && ccwPosition >= 0;
        let embeddableComponents: EmbeddableComponent[] = [];
        if (shouldRender) {
            const ccwEmbeddableRootObject = {
                position: ccwPosition,
                type: "div",
                attributes: {
                    [CONTEXTUAL_RECOMMENDS_WIDGET_DATA_ATTRIBUTE]: "true",
                    class: "usc-embedded-ccw"
                }
            };
            embeddableComponents = [ccwEmbeddableRootObject];
        }

        const areSponsoredCardsAllowed =
            !this.config.enablePageTags ||
            isPageWhitelisted(this.config.enablePageTags);

        await window.uscEmbeddableWidget?.init({
            config: this.config,
            rootElements,
            embeddableComponents,
            areSponsoredCardsAllowed
        });

        if (shouldRender) {
            await this.showRecommendations(recommendParams);
        }
    },

    config: {} as WidgetConfig
} as BootstrapWidgetApi;
