// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, h } from "preact";
import classNames from "classnames";

import { toRenewalTimeUnit } from "../../utils/misc";
import { getExpirationDate } from "../../utils/expiration-date";
import { ShoppingCartIcon } from "./icons/ShoppingCartIcon";
import { CloseIcon } from "./icons/CloseIcon";
import { formatCartQuantity } from "../../utils/shopping-cart";
import { getPersistedState, savePersistedState } from "../../app/state";
import { Placement } from "../../app/config";

import "./ShoppingCartButton.scss";

const POPOVER_CLASS_NAME = "usc-shopping-cart-popover";

interface ShoppingCartProps {
    lang: string;
    cartItemsCount: number;
    isHidden: boolean;
    storageKey: string;
    popoverRenewalTimeAmount: number;
    popoverRenewalTimeUnit: string;
    openShoppingCartFn: () => void;
    isHideButton: boolean;
    placement?: Placement;
    tooltipEnabled: boolean;
}

interface ShoppingCartState {
    expirationDate: number;
    isPopoverTouched: boolean;
}

export default class ShoppingCartButton extends Component<
    ShoppingCartProps,
    ShoppingCartState
> {
    // eslint-disable-next-line react/state-in-constructor
    state: ShoppingCartState;

    constructor(props: ShoppingCartProps) {
        super(props);

        const storedState = getPersistedState(props.storageKey);

        this.state = {
            expirationDate: storedState.expirationDate ?? 0,
            isPopoverTouched: storedState.isPopoverTouched ?? false
        };
    }

    componentDidMount() {
        const { expirationDate } = this.state;
        const { popoverRenewalTimeUnit, popoverRenewalTimeAmount } = this.props;
        const castedPopoverRenewalTimeUnit = toRenewalTimeUnit(
            popoverRenewalTimeUnit
        );
        if (Date.now() > expirationDate) {
            const date = getExpirationDate(
                castedPopoverRenewalTimeUnit,
                popoverRenewalTimeAmount
            );
            const isPopoverTouched = false;

            this.saveState(date, isPopoverTouched);
        }

        document.addEventListener("click", this.handleDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleDocumentClick);
    }

    handleDocumentClick = (e: MouseEvent) => {
        const { isPopoverTouched } = this.state;
        const { isHidden } = this.props;
        const target = e.target as HTMLElement;
        const className = String(target.className);

        if (
            !isPopoverTouched &&
            !isHidden &&
            !className.includes(POPOVER_CLASS_NAME)
        ) {
            this.handlePopoverClose();

            document.removeEventListener("click", this.handleDocumentClick);
        }
    };

    handlePopoverClose = () => {
        this.markAsTouched();
    };

    private markAsTouched = () => {
        const { popoverRenewalTimeUnit, popoverRenewalTimeAmount } = this.props;
        const castedPopoverRenewalTimeUnit = toRenewalTimeUnit(
            popoverRenewalTimeUnit
        );
        const expirationDate = getExpirationDate(
            castedPopoverRenewalTimeUnit,
            popoverRenewalTimeAmount
        );
        const isPopoverTouched = true;

        this.saveState(expirationDate, isPopoverTouched);
    };

    private saveState = (expirationDate: number, isPopoverTouched: boolean) => {
        const { storageKey } = this.props;
        this.setState({
            expirationDate,
            isPopoverTouched
        });

        const persistedState = getPersistedState(storageKey);

        persistedState.expirationDate = expirationDate;
        persistedState.isPopoverTouched = isPopoverTouched;

        savePersistedState(storageKey, persistedState);
    };

    private handleShoppingBtnClick = (e: Event) => {
        const { isPopoverTouched } = this.state;
        const { openShoppingCartFn } = this.props;
        if (!isPopoverTouched) {
            this.markAsTouched();
        }
        e.preventDefault();
        openShoppingCartFn();
    };

    render() {
        const {
            isHidden: isMovedOutsideScreen,
            lang,
            cartItemsCount,
            isHideButton,
            placement,
            tooltipEnabled
        } = this.props;
        const { isPopoverTouched } = this.state;

        const isButtonHidden =
            window.uscWidget?.config?.hideCartButton || isHideButton;

        return (
            <div
                className={classNames("usc-shopping-cart-btn-container", {
                    "usc-shopping-cart-btn-container--top": placement === "top",
                    "usc-shopping-cart-btn-container--center":
                        placement === "center",
                    "is-hidden": isMovedOutsideScreen,
                    "hide-button": isButtonHidden
                })}
                aria-hidden={isButtonHidden ? "true" : "false"}
            >
                {!isPopoverTouched &&
                    !isMovedOutsideScreen &&
                    tooltipEnabled && (
                        <div className={POPOVER_CLASS_NAME} role="tooltip">
                            <div className="usc-shopping-cart-tooltip-arrow" />
                            <div className="usc-shopping-cart-popover-body">
                                <div className="usc-shopping-cart-popover-title">
                                    {lang.startsWith("es")
                                        ? "Comprar productos destacados"
                                        : "Shop featured products"}
                                </div>
                                <button
                                    type="button"
                                    className="usc-shopping-cart-popover-close-btn"
                                    aria-label="Close"
                                    onClick={this.handlePopoverClose}
                                >
                                    <CloseIcon />
                                </button>
                            </div>
                        </div>
                    )}
                <button
                    type="button"
                    className="usc-shopping-cart"
                    onClick={this.handleShoppingBtnClick}
                    aria-label="Open Universal shopping cart"
                >
                    <ShoppingCartIcon
                        cartItemsCount={formatCartQuantity(cartItemsCount)}
                    />
                </button>
            </div>
        );
    }
}
