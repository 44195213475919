import { Analytics } from "common";
import { trackCitrusClick, trackCitrusImpression } from "common/lib/service";

export class ReportService {
    private uniqueReportStorage = new Set<string>();

    // eslint-disable-next-line class-methods-use-this
    public async sendClick(e: MouseEvent) {
        try {
            const networkBrand = window.uscWidget.config?.networkBrand;
            const target = e.target as HTMLElement;
            const currTarget = e.currentTarget as HTMLElement;
            const citrusAdId =
                currTarget.dataset.productAdId || target.dataset.productAdId;
            if (networkBrand && citrusAdId) {
                await trackCitrusClick(networkBrand, citrusAdId);
            }
        } catch (error) {
            console.error("USC: [Citrus] Click Reporting error.", error);
        }
    }

    public sendImpression(entries: IntersectionObserverEntry[]) {
        const networkBrand = window.uscWidget.config?.networkBrand;
        entries?.forEach(async entry => {
            try {
                const element = entry.target as HTMLElement;
                const citrusAdId = element.dataset.productAdId;
                if (
                    networkBrand &&
                    entry.isIntersecting &&
                    citrusAdId &&
                    !this.uniqueReportStorage.has(citrusAdId)
                ) {
                    this.uniqueReportStorage.add(citrusAdId);
                    await trackCitrusImpression(networkBrand, citrusAdId);
                }
            } catch (e) {
                const element = entry.target as HTMLElement;
                const citrusAdId = element.dataset.productAdId;
                if (citrusAdId) {
                    this.uniqueReportStorage.delete(citrusAdId);
                }

                Analytics.logError(e);
                console.error(
                    "USC: [Citrus] viewPort error tracking impression.",
                    e
                );
            }
        });
    }

    public resetReports(): void {
        this.uniqueReportStorage.clear();
    }
}
