// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, h, createRef } from "preact";
import classNames from "classnames";

import {
    SHOPPING_OUTLET,
    SHOPPING_OUTLET_CONTENT,
    CLASS_NAME_FOR_SHOWN_WIDGET_ON_BODY
} from "../../constants/components.registry";
import {
    getShoppableProducts,
    CHANNEL_KEY_ATTRIBUTE,
    PRODUCT_KEY_ATTRIBUTE,
    updateBodyClassList,
    ProductPlacement
} from "../../utils/dom";
import { Theme } from "../../constants/themes";
import { clearLocationHash } from "../../utils/url";
import { isIPhone } from "../../utils/platform";
import { loadWidget, loadWidgetV3 } from "../../utils/loader";

import "./ShoppingOutlet.scss";

interface ShoppingOutletProps {
    theme: Theme;
    networkBrand: string;
    lang: string;
    onCartAmountChange: (cartAmount: number) => void;
    iframeProducts: ProductPlacement[];
    hideShoppingButton: (isHide: boolean) => void;
}

interface ShoppingOutletState {
    isHidden: boolean;
    isLoading: boolean;
}

export default class ShoppingOutlet extends Component<
    ShoppingOutletProps,
    ShoppingOutletState
> {
    // eslint-disable-next-line react/state-in-constructor
    state: ShoppingOutletState = {
        isHidden: true,
        isLoading: true
    };

    uscRootRef = createRef<HTMLDivElement>();

    componentDidMount() {
        window.addEventListener("click", this.onClickOutside, true);
        window.addEventListener("orientationchange", this.onOrientationChange);
    }

    componentDidUpdate() {
        const { isHidden } = this.state;

        if (!isHidden) {
            this.uscRootRef?.current?.focus();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.onClickOutside);
        window.removeEventListener(
            "orientationchange",
            this.onOrientationChange
        );
    }

    private onOrientationChange = (): void => {
        // (IPhones only): This thing enforces layout recalculation by resetting fixed position attached with the class
        // eslint-disable-next-line react/destructuring-assignment
        if (isIPhone() && !this.state.isHidden) {
            document.body.classList.remove(CLASS_NAME_FOR_SHOWN_WIDGET_ON_BODY);
            setTimeout(() => {
                document.body.classList.add(
                    CLASS_NAME_FOR_SHOWN_WIDGET_ON_BODY
                );
            }, 400);
        }
    };

    private onClickOutside = (event: MouseEvent): void => {
        const { isHidden } = this.state;

        if (
            !(
                (event.target as HTMLElement).closest(
                    `[data-${PRODUCT_KEY_ATTRIBUTE}][data-${CHANNEL_KEY_ATTRIBUTE}]`
                ) ||
                (event.target as HTMLElement).closest(`#${SHOPPING_OUTLET}`) ||
                (event.target as HTMLElement).closest("[href^='#usc-']") ||
                (event.target as HTMLElement).closest(".usc-modal")
            ) &&
            !isHidden
        ) {
            this.hideUSCWidget();
        }
    };

    public hideUSCWidget() {
        const { hideShoppingButton } = this.props;

        this.setState({
            isHidden: true
        });

        clearLocationHash();
        hideShoppingButton(false);
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    async renderOutlet(onRenderOutletCallback: () => void) {
        try {
            const {
                iframeProducts,
                lang,
                networkBrand,
                onCartAmountChange,
                theme
            } = this.props;

            if (!window.usc) {
                this.setState({
                    isLoading: true,
                    isHidden: false
                });

                if (window.uscWidget?.config?.uscRevamp) {
                    await loadWidgetV3(theme, networkBrand);
                } else {
                    await loadWidget(theme, networkBrand);
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.usc.onWidgetClose = () => {
                    this.hideUSCWidget();
                };

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.usc.onCartAmountChange = (cartAmount: number): void => {
                    onCartAmountChange(cartAmount);
                };

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.usc.setPageProducts(
                    getShoppableProducts(iframeProducts)
                );
            } else {
                this.setState({
                    isHidden: false
                });
            }

            if (this.uscRootRef.current) {
                window.usc.unmountWidget(this.uscRootRef.current);
            }

            onRenderOutletCallback();

            if (this.uscRootRef.current) {
                window.usc.renderWidget(this.uscRootRef.current, {
                    theme,
                    lang
                });
            }

            this.setState({
                isLoading: false
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log("USC: can not initialize usc", error);
        }
    }

    render() {
        const { isHidden, isLoading } = this.state;

        updateBodyClassList(isHidden);

        return (
            <div
                className={classNames("usc-outlet", {
                    "is-hidden": isHidden
                })}
                aria-hidden={isHidden ? "true" : "false"}
            >
                {isLoading && (
                    <div className="usc-outlet-loader">
                        <div aria-live="assertive" className="lds-ripple">
                            <div />
                            <div />
                        </div>
                    </div>
                )}
                <div
                    aria-label="Shopping cart available"
                    className={classNames("usc-root", {
                        "usc-root_hidden": isHidden
                    })}
                    id={SHOPPING_OUTLET_CONTENT}
                    ref={this.uscRootRef}
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    tabIndex={0}
                />
            </div>
        );
    }
}
