import { WidgetConfig, RecommendProduct } from "common/lib/interfaces";
import { Theme } from "src/constants/themes";

import { CONTEXTUAL_RECOMMENDS_WIDGET_DATA_ATTRIBUTE } from "../constants/components.registry";

declare const BASE_PUBLIC_URL: string;
declare const BASE_API_URL: string;
declare const ENTRY_POINT: string;

export const getScriptQueryParams = (): string => {
    const script: HTMLScriptElement | null = document.querySelector(
        `script[src*="${ENTRY_POINT}.js"]`
    );

    const src = script?.src ?? "";

    const index = src.indexOf("?");

    return index !== -1 ? src.substring(index) : "";
};

export const loadCSS = async (
    url: string,
    id: string,
    element: HTMLElement = document.head
) => {
    const params = getScriptQueryParams();

    return !document.getElementById(id)
        ? new Promise((resolve, reject) => {
              const linkEl = document.createElement("link");
              linkEl.href = `${url}${params}`;
              linkEl.rel = "stylesheet";
              linkEl.type = "text/css";
              linkEl.id = id;
              linkEl.onload = () => resolve(1);
              linkEl.onerror = err => reject(err);
              element.appendChild(linkEl);
          })
        : Promise.resolve();
};

export const loadWidget = async (theme: Theme, networkBrand: string) =>
    Promise.all([
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line import/extensions
        import("widget/usc.js"),
        loadCSS(`${BASE_PUBLIC_URL}/widget.css`, "usc-styles"),
        loadCSS(
            `${BASE_API_URL}/v3/${networkBrand}/theme/${theme}`,
            "usc-theme-styles"
        )
    ]).catch(err => {
        console.log("USC: can not load widget' css/js", err);
    });

export const loadWidgetV3 = async (theme: Theme, networkBrand: string) =>
    Promise.all([
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line import/extensions
        import("widgetV3/usc-v3.js"),
        loadCSS(`${BASE_PUBLIC_URL}/widget-v3.css`, "usc-styles"),
        loadCSS(
            `${BASE_API_URL}/v3/${networkBrand}/theme/${theme}`,
            "usc-theme-styles"
        )
    ]).catch(err => {
        console.log("USC: can not load widget' css/js", err);
    });

export const loadAmazonPayScript = (): void => {
    const script = document.createElement("script");
    script.src = "https://static-na.payments-amazon.com/checkout.js";
    document.body.appendChild(script);
};

export const loadContextualRecommendationsWidget = async (
    config: WidgetConfig,
    recommendProducts: RecommendProduct[],
    productsCount: number,
    areSponsoredCardsAllowed: boolean
): Promise<void> => {
    const rootElements = document.querySelectorAll(
        `[${CONTEXTUAL_RECOMMENDS_WIDGET_DATA_ATTRIBUTE}]`
    );
    if (!rootElements.length) {
        return;
    }

    Promise.all([
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line import/extensions
        import("contextualWidget/uscContextualWidget.js"),
        loadCSS(
            `${BASE_PUBLIC_URL}/uscContextualWidget.css`,
            "usc-contextual-styles"
        ),
        loadCSS(
            `${BASE_API_URL}/v3/${config.networkBrand}/theme/${config.theme}`,
            "usc-theme-styles"
        )
    ])
        .then(() => {
            if (window.uscContextualWidget) {
                const recommendationConfig = {
                    ...config,
                    recommendProducts,
                    productsCount,
                    areSponsoredCardsAllowed
                };

                window.uscContextualWidget.showRecommendations(
                    recommendationConfig,
                    rootElements
                );
            }
        })
        .catch(err => {
            console.log(
                "USC: can not load contextual recommends widget' css/js",
                err
            );
        });
};

export const loadEmbeddableProductsWidget = async (config: WidgetConfig) => {
    return Promise.all([
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line import/extensions
        import("embeddableWidget/uscEmbeddableProductsWidget.js"),
        loadCSS(
            `${BASE_PUBLIC_URL}/uscEmbeddableProductsWidget.css`,
            "usc-embeddable-products-styles"
        ),
        loadCSS(
            `${BASE_API_URL}/v3/${config.networkBrand}/theme/${config.theme}`,
            "usc-theme-styles"
        )
    ]);
};

export const loadPromisePolyfill = () => {
    if (!("onunhandledrejection" in window)) {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            import("../promise-polyfill");
        } catch (e) {
            console.log("USC: can't load promise polyfill", e);
        }
    }
};

type TLoadDefaultConfig = (
    networkBrand?: string,
    theme?: string
) => Promise<WidgetConfig>;
export const loadDefaultConfig: TLoadDefaultConfig = async (
    networkBrand = "nbcu-trial",
    theme = "some-name"
) => {
    try {
        const params = getScriptQueryParams();

        const response = await fetch(
            `${BASE_API_URL}/v3/${networkBrand}/theme/${theme}/config${params}`
        );
        const defaultConfig = await response.json();
        return defaultConfig || {};
    } catch (error) {
        console.error("USC: fetch config failed.", error);
        return {};
    }
};
