import Analytics from "./analytics";
export class SessionStorageService {
    static setItem(key, value) {
        try {
            if (typeof window === "undefined") {
                return;
            }
            window.sessionStorage.setItem(key, value);
        }
        catch (err) {
            Analytics.logError(err);
        }
    }
    static getItem(key) {
        try {
            if (typeof window === "undefined") {
                return null;
            }
            return window.sessionStorage.getItem(key);
        }
        catch (err) {
            Analytics.logError(err);
            return null;
        }
    }
}
