import { DEFAULT_LANG, RenewalTimeUnit } from "common/lib/constants";
import { WidgetConfig } from "common/lib/interfaces";

export const MAX_Z_INDEX = 2147483647; // 2 ** 31 - 1;
export const MIN_Z_INDEX = -MAX_Z_INDEX;
export const DEFAULT_Z_INDEX = 99999999;

export const validateZIndex = (zIndex?: number): number => {
    if (typeof zIndex !== "number") {
        return DEFAULT_Z_INDEX;
    }

    return Math.min(MAX_Z_INDEX, Math.max(MIN_Z_INDEX, zIndex));
};

export const setZIndex = (zIndex: number): void => {
    const css = document.createElement("style");
    css.innerHTML = `:root {--usc-outlet-z-index: ${zIndex};}`;
    document.body.appendChild(css);
};

export const validateConfig = (config: WidgetConfig): WidgetConfig => {
    if (!config?.lang?.startsWith("en") && !config?.lang?.startsWith("es")) {
        return { ...config, lang: DEFAULT_LANG };
    }

    return config;
};

export function toRenewalTimeUnit(renewalTimeUnit: string) {
    return Object.values<string>(RenewalTimeUnit).includes(renewalTimeUnit)
        ? (renewalTimeUnit as RenewalTimeUnit)
        : RenewalTimeUnit.Days;
}
