import { InitializeIdentity } from "../app/config";
import { getIdentityScriptElements } from "./dom";

declare global {
    interface Window {
        nbc?: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            identity?: any;
        };
    }
}

const loadIdentityScript = (sdkUrl: string) =>
    new Promise((resolve, reject) => {
        const identityScript = document.createElement("script");
        identityScript.setAttribute("src", sdkUrl);

        identityScript.onload = () => resolve(1);
        identityScript.onerror = err => reject(err);

        document.head.appendChild(identityScript);
    });

const getIdentityInitializationParams = (
    configUrl: string
): { configLocationHost: string; key: string } => {
    const lastSlashIndex = configUrl.lastIndexOf("/");
    const lastDotIndex = configUrl.lastIndexOf(".");
    const configLocationHost = configUrl.substring(0, lastSlashIndex);
    const key = configUrl.substring(lastSlashIndex + 1, lastDotIndex);

    if (!configLocationHost || !key) {
        throw new Error("USC: [Identity] Invalid configUrl param");
    }

    return { configLocationHost, key };
};

export const checkInitializeIdentity = (
    identityConfig?: InitializeIdentity
): boolean => {
    const identityScriptElements = getIdentityScriptElements();

    return !!(
        identityConfig?.enabled &&
        identityConfig?.configUrl &&
        identityConfig?.sdkUrl &&
        identityConfig?.env &&
        !window?.nbc?.identity &&
        !identityScriptElements.length
    );
};

export const initializeIdentity = async ({
    configUrl,
    env,
    sdkUrl
}: Required<InitializeIdentity>): Promise<void> => {
    try {
        await loadIdentityScript(sdkUrl);

        const { configLocationHost, key } =
            getIdentityInitializationParams(configUrl);

        if (window?.nbc?.identity) {
            window.nbc.identity.initialize(key, {
                checkoutEnabled: true,
                configLocationHost,
                env,
                useBaseSdkLocation: true
            });
        }
    } catch (error) {
        console.log(error);
    }
};
