// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../node_modules/sass-loader/dist/cjs.js!./default.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".usc-widget-is-shown{width:100%}.usc-widget-is-shown img{user-select:none;-moz-user-select:none;-webkit-user-drag:none;-webkit-user-select:none;-ms-user-select:none}@media (max-width: 768px){.usc-widget-is-shown{position:fixed;overflow:hidden}}\n", "",{"version":3,"sources":["webpack://./src/styles/main.scss"],"names":[],"mappings":"AAKA,qBACI,UAAW,CADf,yBAGQ,gBAAiB,CACjB,qBAAsB,CACtB,sBAAuB,CACvB,wBAAyB,CACzB,oBAAqB,CACxB,0BAID,qBACI,cAAe,CACf,eAAgB,CACnB","sourcesContent":["@import \"~sass-mediaqueries/media-queries\";\n@import \"variables\";\n@import \"mixins\";\n@import \"default.css\";\n\n.usc-widget-is-shown {\n    width: 100%;\n    img {\n        user-select: none;\n        -moz-user-select: none;\n        -webkit-user-drag: none;\n        -webkit-user-select: none;\n        -ms-user-select: none;\n    }\n}\n\n@media (max-width: $usc-screen-xs) {\n    .usc-widget-is-shown {\n        position: fixed;\n        overflow: hidden;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
