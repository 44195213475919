export var USCTab;
(function (USCTab) {
    USCTab["ProductDetails"] = "ProductDetails";
    USCTab["OrderLookup"] = "OrderLookup";
    USCTab["OrderHistory"] = "OrderHistory";
    USCTab["StartReturn"] = "StartReturn";
    USCTab["Cart"] = "Cart";
    USCTab["Checkout"] = "Checkout";
    USCTab["ThankYou"] = "ThankYou";
    USCTab["ShopAll"] = "ShopAll";
})(USCTab || (USCTab = {}));
export var RenewalTimeUnit;
(function (RenewalTimeUnit) {
    RenewalTimeUnit["Seconds"] = "seconds";
    RenewalTimeUnit["Minutes"] = "minutes";
    RenewalTimeUnit["Hours"] = "hours";
    RenewalTimeUnit["Days"] = "days";
})(RenewalTimeUnit || (RenewalTimeUnit = {}));
export var Theme;
(function (Theme) {
    Theme["Default"] = "default";
    Theme["Bravo"] = "bravo";
    Theme["Telemundo"] = "telemundo";
    Theme["SyFy"] = "syfy";
    Theme["Today"] = "today";
    Theme["Eonline"] = "eonline";
    Theme["Oxygen"] = "oxygen";
    Theme["Sports"] = "sports";
    Theme["NBC"] = "nbc";
    Theme["CNBC"] = "cnbc";
    Theme["CNBCMakeit"] = "cnbcmakeit";
    Theme["NewYork"] = "newyork";
})(Theme || (Theme = {}));
