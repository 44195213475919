import { Component } from "preact";

import { loadCSS } from "../../utils/loader";

declare const BASE_PUBLIC_URL: string;

type StageChangeEvent = CustomEvent<{ stage?: number }>;

declare global {
    interface Window {
        revampStage?: number;
        revampStages?: Array<number>;
    }
    interface WindowEventMap {
        ["stagechange"]: StageChangeEvent;
    }
}

export default class StageListener extends Component {
    componentDidMount() {
        if (window.revampStage) {
            this.onStageChange(window.revampStage);
        }
        window.addEventListener("stagechange", (event: StageChangeEvent) => {
            this.onStageChange(event?.detail?.stage);
        });
    }

    componentWillUnmount() {
        window.addEventListener("stagechange", (event: StageChangeEvent) => {
            this.onStageChange(event?.detail?.stage);
        });
    }

    // eslint-disable-next-line class-methods-use-this
    private onStageChange = async (stage?: number) => {
        if (stage === 0) {
            const stageLinkElements = document.querySelectorAll(
                '[id^="widget-v3-stage-"]'
            );
            stageLinkElements?.forEach(stageLinkElement => {
                stageLinkElement.remove();
            });
            window.revampStages = [];
            return;
        }

        if (!stage) {
            return;
        }

        if (stage >= 1) {
            const stageCSS = document.querySelector(
                `[id=widget-v3-stage-${stage}]`
            );

            if (!stageCSS) {
                await loadCSS(
                    `${BASE_PUBLIC_URL}/widget-v3-stage-${stage}.css`,
                    `widget-v3-stage-${stage}`,
                    document.body
                );
                window.revampStages = Array.from(
                    { length: stage },
                    (_, index) => index + 1
                );
            }
        }
    };

    render() {
        return null;
    }
}
