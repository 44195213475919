export const NAMESPACE = "usc";

export const SHOPPING_OUTLET = `${NAMESPACE}-outlet`;
export const SHOPPING_OUTLET_CONTENT = `${NAMESPACE}-outlet-content`;
export const CLASS_NAME_FOR_SHOWN_WIDGET_ON_BODY = "usc-widget-is-shown";

export const CONTEXTUAL_RECOMMENDS_WIDGET_DATA_ATTRIBUTE =
    "data-usc-contextual-recommends-widget";
export const EMBEDDABLE_PRODUCTS_WIDGET_DATA_ATTRIBUTE =
    "data-usc-embeddable-products-widget";
