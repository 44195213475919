import Analytics from "./analytics";
export class LocalStorageService {
    static setItem(key, value) {
        try {
            if (typeof window === "undefined") {
                return;
            }
            window.localStorage.setItem(key, value);
        }
        catch (err) {
            Analytics.logError(err);
        }
    }
    static getItem(key) {
        try {
            if (typeof window === "undefined") {
                return null;
            }
            return window.localStorage.getItem(key);
        }
        catch (err) {
            Analytics.logError(err);
            return null;
        }
    }
}
