export const ORDER_LOOKUP_LOCATION_HASH = "#usc-order-lookup";
export const START_A_RETURN_LOCATION_HASH = "#usc-start-a-return";
export const ORDER_HISTORY_LOCATION_HASH_PREFIX = "#usc-order-history";
export const PDP_LOCATION_HASH_PREFIX = "#usc-pdp";
export const SHOP_ALL_HASH_PREFIX = "#usc-shop-all";
export const CART_LOCATION_HASH_PREFIX = "#usc-cart";
export const USC_PREFIX = "#usc";
export const AMAZON_CHECKOUT_HASH_PREFIX = "#completeCheckout";

export const AMAZON_CHECKOUT_SESSION_ID_QUERY_PARAM = "amazonCheckoutSessionId";

export const AMAZON_CHECKOUT_REGEXP = new RegExp(
    `[\\?&]${AMAZON_CHECKOUT_SESSION_ID_QUERY_PARAM}=[^&]+`
);

export const INCOMING_MESSAGE_ERRORS = {
    wrongFormat:
        "Wrong data format. Please ensure that it contains following properties: data.product.key, data.channel.key"
};

export enum IFrameMessageType {
    PRODUCT_CLICKED = "product",
    PRODUCT_VIEWED = "product_viewed",
    PRODUCTS_LIST = "list_of_products",
    SHOPPABLE_CONTENT_LOADED = "shoppable_content_load",
    CCW_SHOPPABLE_CONTENT_LOADED = "ccw_shoppable_content_load",
    EPW_SHOPPABLE_CONTENT_LOADED = "epw_shoppable_content_load",
    PAGE_SEGMENT_NAME = "page_segment_name_iframe_message_type"
}

export interface RefProductMetadata {
    "product-key": string;
    "channel-key": string;
    "product-sku": string;
    "product-revenue-entity": string;
}

export function parseQuery<T>(queryString: string) {
    const query: Record<string, string> = {};
    const pairs = (
        queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (let i = 0; i < pairs.length; i += 1) {
        const pair = pairs[i].split("=");
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query as T;
}

export const clearLocationHash = (): void => {
    if (
        window.location.hash === ORDER_LOOKUP_LOCATION_HASH ||
        window.location.hash === START_A_RETURN_LOCATION_HASH ||
        window.location.hash.includes(ORDER_HISTORY_LOCATION_HASH_PREFIX) ||
        window.location.hash.includes(PDP_LOCATION_HASH_PREFIX) ||
        window.location.hash.includes(SHOP_ALL_HASH_PREFIX)
    ) {
        window.history.pushState(
            {},
            "",
            window.location.pathname + window.location.search
        );

        window.dispatchEvent(new HashChangeEvent("hashchange"));
    }
};

interface IframeMessage {
    usc: {
        type: string;
        data: {
            product: {
                key?: string;
                sku?: string;
            };
            channel: {
                key: string;
            };
            adDetails?: {
                eaid?: string;
                eadv?: string;
                ebuy?: string;
                ecid?: string;
                eenv?: string;
                epid?: string;
                esid?: string;
                vendor?: string;
            };
        };
    };
}

export const isMessageExistKeys = (message: IframeMessage) =>
    (!!message.usc.data?.product?.sku || !!message.usc.data?.product?.key) &&
    !!message.usc.data?.channel?.key;
