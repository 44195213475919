import { Analytics, getContextualRecommendationParams } from "common/lib/utils";
import { RecommendParams } from "common/lib/interfaces/configs";
import { RecommendProduct } from "common/lib/interfaces/product";

declare const ENV: string;

const SANDBOX_MODE = ENV !== "public";

export const getRecommendations = async (
    { tags, limit }: { tags?: RecommendParams["tags"]; limit: number },
    personifyApiUrl: string
): Promise<RecommendProduct[]> => {
    const recommendationParams = getContextualRecommendationParams();
    const tagsFilter = tags?.map(tag => ({ item_id: tag })) ?? [];
    const pageSize = limit * 2;

    const recommendationBody = {
        ...recommendationParams,
        sessionid: Analytics.getSessionId(),
        pageSize,
        overrides: [
            {
                rule: "ALL",
                type: "limitCategories",
                items: tagsFilter
            }
        ],
        test: SANDBOX_MODE,
        includescores: true
    };

    try {
        const request = await fetch(`${personifyApiUrl}/getrecs`, {
            method: "POST",
            body: JSON.stringify(recommendationBody)
        });

        const response = await request.json();
        return response?.recommendations || [];
    } catch (error) {
        console.error(error);

        return [];
    }
};
