export enum RenewalTimeUnit {
    Seconds = "seconds",
    Minutes = "minutes",
    Hours = "hours",
    Days = "days"
}

const DEFAULT_TIME_AMOUNT = 60;

export const ExpirationDateCalculator = {
    [RenewalTimeUnit.Seconds]: (seconds: number): number => {
        return Date.now() + seconds * 1000;
    },

    [RenewalTimeUnit.Minutes]: (minutes: number): number => {
        return Date.now() + minutes * 1000 * 60;
    },

    [RenewalTimeUnit.Hours]: (hours: number): number => {
        return Date.now() + hours * 1000 * 60 * 60;
    },

    [RenewalTimeUnit.Days]: (days: number): number => {
        return Date.now() + days * 1000 * 60 * 60 * 24;
    }
};

export const getExpirationDate = (
    popoverRenewalTimeUnit: RenewalTimeUnit = RenewalTimeUnit.Days,
    popoverRenewalTimeAmount = DEFAULT_TIME_AMOUNT
): number =>
    ExpirationDateCalculator[popoverRenewalTimeUnit](popoverRenewalTimeAmount);
