// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h } from "preact";

import "./CloseIcon.scss";

export function CloseIcon() {
    return (
        <svg x="0px" y="0px" viewBox="0 0 12 12">
            <g>
                <g>
                    <line
                        className="close-icon-st0"
                        x1="0.203"
                        y1="0.203"
                        x2="11.797"
                        y2="11.797"
                    />
                    <line
                        className="close-icon-st0"
                        x1="11.797"
                        y1="0.203"
                        x2="0.203"
                        y2="11.797"
                    />
                </g>
            </g>
        </svg>
    );
}
