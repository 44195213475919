/* eslint-disable class-methods-use-this */
import hash from "object-hash";

import { Analytics } from "common/lib/utils";
import omit from "lodash-es/omit";

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mParticle: any;
    }
}

const enum MParticleEvents {
    PRODUCT_CLICKED = "Product clicked",
    PRODUCT_LOADED = "Product loaded",
    PRODUCT_VIEWED = "Product viewed",
    SPONSOR_VIEWED = "Sponsor Viewed",
    SPONSOR_IMPRESSION = "Sponsor Impression",
    SPONSOR_CLICKED = "Sponsor Clicked",
    IAB_IMPRESSION = "IAB Impression",
    SHOPPABLE_CONTENT_LOAD = "Shoppable Content Load",
    SHOPPABLE_PAGE_LOAD = "Shoppable page load"
}

function logOnlyOnce(eventName: MParticleEvents) {
    return function (
        target: EventLogger,
        propertyKey: string,
        descriptor: PropertyDescriptor
    ) {
        const originalMethod = descriptor.value;

        // eslint-disable-next-line no-param-reassign
        descriptor.value = function (...args: object[]) {
            const eventAttributes = { ...args[0], eventName };
            const hashedAttributes = hash(eventAttributes);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!this.uniqueEventStorage.has(hashedAttributes)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                this.uniqueEventStorage.add(hashedAttributes);
                originalMethod.apply(this, args);
            }
        };

        return descriptor;
    };
}

class EventLogger {
    private uniqueEventStorage = new Set<string>();

    public resetSentEvents(): void {
        this.uniqueEventStorage.clear();
    }

    @logOnlyOnce(MParticleEvents.PRODUCT_VIEWED)
    public logProductViewedEvent(attributes: Record<string, unknown>): void {
        Analytics.logCustomEvent(
            MParticleEvents.PRODUCT_VIEWED,
            window.mParticle.EventType.Navigation,
            attributes
        );
    }

    public logProductClickedEvent(attributes: Record<string, unknown>): void {
        window.mParticle.logEvent(
            MParticleEvents.PRODUCT_CLICKED,
            window.mParticle.EventType.UserPreference,
            { ...Analytics.getCommonAttributes(), ...attributes }
        );
    }

    @logOnlyOnce(MParticleEvents.IAB_IMPRESSION)
    public logIABImpressionEvent(
        attributes: Record<string, unknown>,
        attributesToFilter: string[]
    ): void {
        const filteredAttributes = omit(attributes, attributesToFilter);
        window.mParticle.logEvent(
            MParticleEvents.IAB_IMPRESSION,
            window.mParticle.EventType.Navigation,
            { ...Analytics.getCommonAttributes(), ...filteredAttributes }
        );
    }

    public logSponsorClickedEvent(attributes: Record<string, unknown>): void {
        window.mParticle.logEvent(
            MParticleEvents.SPONSOR_CLICKED,
            window.mParticle.EventType.UserPreference,
            { ...Analytics.getCommonAttributes(), ...attributes }
        );
    }

    @logOnlyOnce(MParticleEvents.SPONSOR_VIEWED)
    public logSponsorViewedEvent(
        attributes: Record<string, unknown>,
        attributesToFilter: string[]
    ): void {
        const filteredAttributes = omit(attributes, attributesToFilter);
        window.mParticle.logEvent(
            MParticleEvents.SPONSOR_VIEWED,
            window.mParticle.EventType.Navigation,
            { ...Analytics.getCommonAttributes(), ...filteredAttributes }
        );
    }

    @logOnlyOnce(MParticleEvents.SPONSOR_IMPRESSION)
    public logSponsorImpressionEvent(
        attributes: Record<string, unknown>,
        attributesToFilter: string[]
    ): void {
        const filteredAttributes = omit(attributes, attributesToFilter);
        window.mParticle.logEvent(
            MParticleEvents.SPONSOR_IMPRESSION,
            window.mParticle.EventType.Navigation,
            { ...Analytics.getCommonAttributes(), ...filteredAttributes }
        );
    }

    @logOnlyOnce(MParticleEvents.SHOPPABLE_PAGE_LOAD)
    public logPageLoadEvent<T>(attributes: T): void {
        window.mParticle.logEvent(
            MParticleEvents.SHOPPABLE_PAGE_LOAD,
            window.mParticle.EventType.Navigation,
            { ...Analytics.getCommonAttributes(), ...attributes }
        );
    }

    @logOnlyOnce(MParticleEvents.SHOPPABLE_CONTENT_LOAD)
    public logShoppableContentLoadedEvent<T extends object>(
        attributes: T,
        attributesToFilter: string[]
    ): void {
        const filteredAttributes = omit(attributes, attributesToFilter);
        window.mParticle.logEvent(
            MParticleEvents.SHOPPABLE_CONTENT_LOAD,
            window.mParticle.EventType.Navigation,
            { ...Analytics.getCommonAttributes(), ...filteredAttributes }
        );
    }

    @logOnlyOnce(MParticleEvents.PRODUCT_LOADED)
    public logProductLoadedEvent(attributes: Record<string, unknown>): void {
        Analytics.logCustomEvent(
            MParticleEvents.PRODUCT_LOADED,
            window.mParticle.EventType.Navigation,
            { ...Analytics.getCommonAttributes(), ...attributes }
        );
    }
}

export const eventLogger = new EventLogger();
